.header {
  display: flex;
  border-bottom: 4px solid #000;
}
.header .foresightx {
  flex: 1;
}
.header a {
  color: #000;
  font-weight: 500;
  font-size: 36px;
  display: flex;
  height: 80px;
  line-height: 80px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  justify-content: center;
  font-family: 'DINCond';
}
.header a span {
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
  width: 73px;
  line-height: 14px;
  text-align: left;
  margin-left: 12px;
  font-family: 'Avenir';
}
.header .border-right {
  border-right: 4px solid #000;
}


@media screen and (max-width: 992px) {
  .header .mh {
    display: none;
  }
  .header .border-right.mb0 {
    border: 0;
  }
  .header a {
    font-size: 32px;
    height: 68px;
    line-height: 68px;
  }
}
