.supported-openBuild {
  font-size: 16px !important;
  margin-top: 36px;
  opacity: 0.6;
}
.onboard {
  padding-left: 98px;
  padding-right: 98px;
  position: relative;
  padding-top: 235px;
  overflow: hidden;
}
.onboard > p {
  display: none;
}
.onboard img {
  width: 834px;
  position: absolute;
  top: -48px;
  transform: rotate(-5deg);
  right: 0;
  z-index: -1;
}
.onboard-time {
  display: flex;
  font-size: 38px;
  align-items: center;
  margin-bottom: 80px;
}
.onboard-time p {
  margin-left: 12px;
}
.onboard h2 {
  font-size: 101px;
  font-weight: 900;
}
.onboard h2 span {
  display: inline-block;
  width: 231px;
  font-size: 42px;
  font-weight: 500;
  font-family: 'DINCond';
}

.build {
  position: relative;
  padding-left: 143px;
  padding-right: 143px;
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.build h3 {
  color: #000;
  font-family: 'DINCond';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 56px;
}
.build-logo {
  left: 100px;
  top: -30px
}
.build p {
  max-width: 980px;
  font-size: 28px;
  line-height: 44px;
  font-weight: 400;
}
.build p.text-two {
  margin-top: 56px;
}
.build p span {
  font-weight: 800;
}
.build img{
  position: absolute;
  z-index: -1;
}

.be-button .black {
  display: none;
}
.be-button:hover .black {
  display: block;
}
.rfm-child {
  height: 44px;
  line-height: 44px;
}
.build-bg {
  top: -83px;
  margin-left: 300px;
}
.build a {
  margin-top: 56px;
  display: block;
  width: 310.936px;
  height: 59.27px;
  flex-shrink: 0;
}
.hacker-text {
  margin-top: 120px;
  color: #FFF;
  font-family: 'DINCond';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; 
  background-color: #000;
  padding-top: 2px;
  padding-bottom: 2px;
}
.hacker-text hr {
  height: 2px;
  background-color: #fff;
  margin: 2px 0;
  border: 0;
}

.function {
  margin-top: 80px;
  /* padding-left: 120px;
  padding-right: 120px; */
  max-width: 1200px;
  margin: 80px auto 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px;
  grid-auto-rows: minmax(100px, auto);
}
.function > div {
  text-align: center;
}
.function > div > span {
  font-size: 80px;
}
.function > div > h4 {
  font-size: 34px;
  margin-top: 44px;
  margin-bottom: 22px;
  font-weight: 800;
}
.function > div > p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.venue {
  padding-left: 200px;
  margin-top: 90px;
  position: relative;
}
.venue h3 {
  font-family: 'DINCond';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 56px;
}
.venue > img {
  position: absolute;
  z-index: -1;
  top: -30px;
  margin-left: 230px;
}
.venue > div {
  display: flex;
}
.venue-map {
  border: 4px solid #000;
  padding: 20px;
}
.venue-map img {
  max-width: 580px;
}
.venue-text {
  margin-left: 56px;
}
.venue-text h6 {
  font-size: 26px;
  line-height: 37px;
  margin-bottom: 36px;
  font-weight: 900;
}
.venue-text p {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
}
.venue-text p:last-of-type {
  margin-bottom: 32px;
}
.summi {
  display: flex;
  max-width: 1384px;
  padding: 0 20px;
  margin: 160px auto 0px;
}
.summi-left {
  margin-right: 80px;
  flex: 1
}
.summi-left-time {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  margin-bottom: 60px;
}
.summi-left-time strong {
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 37px; 
  margin-left: 20px;
}
.summi-left hr {
  border-top: 2px solid #000;
  margin-bottom: 60px;
}
.summi-left-title {
  font-family: 'DINCond';
  font-size: 106px;
  font-style: normal;
  font-weight: 500;
  line-height: 110px;
  max-width: 514px;
  margin-bottom: 24px;
}
.summi-left-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
.summi-left-text p:last-of-type {
  margin-top: 24px;
  margin-bottom: 36px;
}
.summi-swiper {
  max-width: 612px;
  max-height: 700px;
  flex: 1;
}
.summi-swiper .swiper {
  width: 100%;
  margin-left: 0;
  height: 100%;
}
.summi-swiper .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.4)
}
.summi-swiper .swiper-pagination-bullet-active {
  background-color: #000;
}
.summi-swiper .swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
  bottom: 0;
  top: auto;
  transform: translate3d(0px, -0%, 0);
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  /* height: 100%; */
  object-fit: cover;
  width: calc(100% - 60px);
  height: calc(100% - 20px);
  border: 4px solid #000;
  box-shadow: 8px 8px 0px 0px #000;
}
.offer {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  margin-top: 120px;
}
.offer > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.offer > div {
  display: flex;
  align-items: center;
  padding: 45px;
  justify-content: space-between;
}
.offer > div h3, .offer > div h3 > p {
  font-family: 'DINCond';
  font-size: 106px;
}
.offer-content > div {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.offer-content > div > p:first-of-type {
  font-family: 'DINCond';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
  width: 275px;
  text-align: right;
}
.offer-content > div > p:last-of-type {
  max-width: 445px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-left: 38px;
}
.speakers {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
}
.speakers > img {
  position: absolute;
  z-index: -1;
  top: 20px;
  left: -30px;
}
.speakers h3 {
  font-family: 'DINCond';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-top: 150px;
  margin-bottom: 88px;
  text-align: center;
}
.speakers-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px;
  
  /* grid-auto-rows: minmax(100px, auto); */
}
.speakers-content > div {
  border: 3.45px solid #000;
  display: flex;
  flex-direction: column;
}
.speakers-img {
  padding: 16px;
}
.speakers-img img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.speakers-text {
  padding: 12px 16px 12px 16px;
  border-top: 3.45px solid #000;
}
.speakers-text h6 {
  font-family: 'DINCond';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; 
}
.speakers-text p {
  font-size: 14.8px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
}
.speakers-content > div:hover .speakers-text {
  transition: all 300ms;
}
.speakers-content > div:hover .speakers-text {
  background-color: #000;
  color: #FFF;
  height: 100%;
}
.partners h3, .faq h3 {
  font-family: 'DINCond';
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-top: 136px;
  margin-bottom: 56px;
  text-align: center;
}
.partners{
  max-width: 1384px;
  padding: 0 20px;
  margin: 0 auto;
}
.partners-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
}
.partners-content > div {
  aspect-ratio: 1/1;
  border: 4px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.partners-content > div > img:last-of-type {
  display: none;
}
.partners-content > div:hover img:first-of-type {
  display: none;
}

.partners-content > div:hover {
  background-color: #000;
}

.partners-content > div p {
  position: absolute;
  width: 100%;
  bottom: 24px;
  color: #F05101;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: none;
}
.partners-content > div:hover > img:last-of-type, .partners-content > div:hover p {
  display: block;
}
.faq {
  position: relative;
}
.faq img {
  position: absolute;
  top: -170px;
  z-index: -1; 
  width: 100%;
}
.faq-content {
  max-width: 960px;
  margin: 0 auto;
}
.faq > div {
  background-color: #F05101;
  padding-bottom: 100px;
}
.faq .accordion__button, .faq .accordion__button:hover {
  background-color: transparent;
  padding: 0;
}
.faq .accordion__button::before {
  display: none;
}
.faq .accordion__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq .faq-title {
  font-family: 'DINCond';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  color: #000;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 900px;
}
.faq .accordion__button::after {
  display: inline-block;
  content: '';
  height: 16px;
  width: 16px;
  background: url('./assets/fc.svg') no-repeat;
}
.faq .accordion__button[aria-expanded='true']::after {
  background: url('./assets/fo.svg') no-repeat;
}
.faq .accordion__item + .accordion__item {
  border-top: 4px solid #000;
}
.faq .accordion {
  border: 0;
  border-top: 4px solid #000;
  border-bottom: 4px solid #000;
}
.faq .accordion__panel {
  padding: 0;
  padding-bottom: 36px;
}

.faq-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
@media screen and (max-width: 992px) {
  .onboard {
    text-align: center;
    padding-top: 37.5px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .onboard-time {
    justify-content: center;
    font-size: 19px;
    margin-bottom: 36px;
  }
  .onboard-time svg {
    width: 20px;
    height: 20px;
  }
  .onboard h2 {
    display: inline;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 42px; /* 116.667% */
    letter-spacing: -0.72px;
  }
  .onboard h2 span {
    display: none;
  }
  .onboard img {
    position: relative;
    width: 100%;
  }
  .onboard > p {
    display: block;
    margin-top: 18px;
  }
  .build {
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .build-logo {
    display: none;
  }
  .build h3 {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 24px;
  }
  .build p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px; 
  }
  .build p.text-two {
    margin-top: 16px;
  }
  .build a {
    margin-top: 24px;
  }
  .hacker-text {
    margin-top: 60px;
  }
  .build-bg {
    margin-left: 0;
    width: 100%;
    top: 0;
  }
  .build a, .build a img {
    width: 220px;
  }
  .function {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;
  }
  .function > div > h4 {
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .venue {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 60px;
    overflow: hidden;
  }
  .venue > img {
    margin-left: 0px;
    width: 100%;
    top: 0;
  }
  .venue h3 {
    font-size: 34px;
  }
  .venue > div {
    display: block;
  }
  .venue-map {
    padding: 12px;
  }
  .venue-map img {
    max-width: 100%;
  }
  .venue-text {
    margin-left: 0;
    margin-top: 24px;
  }
  .venue-text h6 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .venue-text p {
    font-size: 18px;
  }
  .summi {
    flex-direction: column;
    margin: 56px auto;
  }
  .summi-left {
    margin-right: 0;
  }
  .summi-left-time, .summi-left hr {
    margin-bottom: 24px;
  }
  .summi-left-title {
    font-size: 53px;
    line-height: 60px;
    margin-bottom: 16px;
  }
  .summi-left-text {
    font-size: 16px;
    line-height: 18px;
  }
  .summi-left-text p:last-of-type {
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .summi-swiper {
    margin-top: 36px;
  }
  .summi-swiper .swiper {
    height: 400px;
  }
  .summi-swiper .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }
  .swiper-slide img {
    width: calc(100% - 45px);
  }
  .offer {
    margin-top: 70px;
  }
  .offer > img {
    transform: rotate(90deg);
    right: -37%;
    top: 50%;
    left: auto;
  }
  .offer > div {
    display: block;
    padding: 20px;
  }
  .offer > div h3, .offer > div h3 > p {
    font-size: 53px;
  }
  .offer-content {
    margin-top: 18px;
  }
  .offer-content > div {
    display: block;
  }
  .offer-content > div > p:first-of-type {
    font-size: 28px;
    text-align: left
  }
  .offer-content > div > p:last-of-type {
    margin-left: 0;
    margin-top: 8px;
  }
  .speakers h3 {
    font-size: 34px;
    margin-top: 16px;
    margin-bottom: 30px;
  }
  .speakers-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }
  .speakers-text {
    padding: 4px 8px;
  }
  .speakers-text h6 {
    font-size: 13.5px;
    line-height: 18px;
  }
  .speakers-text p {
    font-size: 12px;
  }
  .faq > div {
    padding: 0 20px;
    padding-bottom: 100px;
  }
  .faq img {
    top: 0;
  }
  .faq .faq-title {
    font-size: 24px;
    line-height: 24px;
    max-width: 85%;
  }
  .faq-text {
    font-size: 16px;
    line-height: 16px;
  }
  .partners h3, .faq h3 {
    margin-top: 120px;
    font-size: 34px;
    line-height: 40px;
  }
  .partners-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 668px) {
  .partners-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 435px) {
  .partners-content {
    grid-template-columns: repeat(3, 1fr);
  }
  .partners-content div img {
    width: 80%;
  }
  .partners-content > div {
    border-width: 2px;
  }
}